<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
                <h2 class="depTitle">人员信息</h2>
                <div class="RightDiv">
                    <el-form :model="searchForm" class="searForm">
                        <div class="table-title">
                            <div>
                                <el-input v-model="searchForm.nameLike" placeholder="名称" clearable prefix-icon="el-icon-search" @keyup.enter.native="searchList" @blur="searchList" @clear="searchList">
                                </el-input>
                            </div>
                            <div>
                                <el-input v-model="searchForm.workId" placeholder="工号" clearable prefix-icon="el-icon-search" @keyup.enter.native="searchworkId" @blur="searchworkId" @clear="searchworkId">
                                </el-input>
                            </div>
                            <div>
                                <el-select v-model="searchForm.departName" placeholder="请选择科室" clearable @change="searchDep(searchForm.departName)">
                                    <el-option v-for="item in departOpt" :key="item.id" :label="item.name" :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <div>
                                <el-cascader v-model="searchForm.ganpost" expand-trigger="hover" :options="postOptions" placeholder="请选择岗位" popper-class="disableFirstLevel" :props="props" ref='postSelectRef' clearable @change='searchpost'></el-cascader>
                            </div>
                            <div>
                                <el-select v-model="searchForm.bralear" multiple placeholder="请选择学科" clearable @change="searchbra(searchForm.bralear)">
                                    <el-option v-for="item in baroptions" :key="item.id" :label="item.name" :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="index-add">
                                <el-upload ref="uploadone" :headers="token" :action="downurl" :file-list="fileList" :limit="limitUpload" style="display: inline-block" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" :on-success="successFile" :on-progress="uploading">
                                    <el-button type="primary" v-if="importExpClick" :loading="importExpClickTwo">人员信息导入</el-button>
                                    <el-button type="info" v-else :loading="importExpClickTwo"></el-button>
                                </el-upload>
                            </div>
                            <div class="index-add">
                                <el-button type="primary" @click="exportData" v-if="cesuanExpClick" :loading="cesuanExpClickTwo">人员信息导出</el-button>
                                <el-button type="info" v-else :loading="cesuanExpClickTwo"></el-button>
                            </div>
                            <div class="index-add">
                                <el-button type="primary" @click="depaddKeshi">新增人员信息</el-button>
                            </div>
                        </div>
                    </el-form>
                </div>
                <el-table :data="tableData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="workId" label="工号" align="center" width="120">
                    </el-table-column>
                    <el-table-column prop="name" label="姓名" align="center" width="90">
                    </el-table-column>
                    <el-table-column prop="departmentName" label="科室名称" align="center" width="140">
                    </el-table-column>
                    <el-table-column prop="inpatientWardName" label="病区名称" align="center" width="140">
                    </el-table-column>
                    <el-table-column prop="postResponsibility" label="岗位职责名称" width="200" align="center">
                    </el-table-column>
                    <el-table-column prop="disciplineName" label="学科名称" align="center" width="120">
                    </el-table-column>
                    <el-table-column prop="idNumber" label="身份证" align="center" width="170">
                    </el-table-column>
                    <el-table-column prop="age" label="年龄" align="center">
                    </el-table-column>
                    <el-table-column prop="lengthOfService" label="工龄" align="center">
                    </el-table-column>
                    <el-table-column prop="sex" label="性别" align="center" width="55">
                        <template slot-scope="scope">
                            <div v-if="scope.row.sex=='1'">男</div>
                            <div v-else-if="scope.row.sex=='2'">女</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="educationalBackground" label="学历" align="center" width="150">
                    </el-table-column>
                    <el-table-column prop="professionalTitle" label="职称" align="center" width="200">
                    </el-table-column>
                    <el-table-column prop="post" label="职务" align="center">
                    </el-table-column>
                    <el-table-column prop="politicalOutlook" label="政治面貌" align="center" width="80">
                    </el-table-column>
                    <el-table-column prop="authorizedStrength" label="编制" align="center" width="60">
                    </el-table-column>
                    <el-table-column prop="employeeType" label="职工类型" align="center" width="80">
                    </el-table-column>

                    <el-table-column label="操作" align="center" fixed="right" width="150px">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="editKeshi(scope.row)"><i class="el-icon-edit"></i></el-button>
                            <el-button size="mini" type="danger" @click="deleteKeshi(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 点击新增人员信息弹出框 -->
        <el-dialog :title="diaTit" :visible.sync="AdddialogVisible" width="700px" top="1vh" :before-close="AdddialogVisibleClose">
            <el-form :model="AddDateModel" ref="AddDateRef" :rules="AddDateRules" :inline="true" label-width="100px">
                <el-form-item label="工号：" prop="jobnum">
                    <el-input v-model="AddDateModel.jobnum" placeholder="请填写工号"></el-input>
                </el-form-item>
                <el-form-item label="姓名：" prop="name">
                    <el-input v-model="AddDateModel.name" placeholder="请填写姓名"></el-input>
                </el-form-item>
                <el-form-item label="身份证：" prop="idcard">
                    <el-input v-model="AddDateModel.idcard" placeholder="请填写身份证"></el-input>
                </el-form-item>
                <el-form-item label="性别：" prop="sex">
                    <el-select v-model="AddDateModel.sex" placeholder="请选择性别">
                        <el-option v-for="item in sexoptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="出生日期：" prop="age" class="ageClas">
                    <el-date-picker value-format="yyyy-MM-dd" v-model="AddDateModel.age" type="date" placeholder="请选择出生日期">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="工龄：" prop="lengthOfService">
                    <el-select v-model="AddDateModel.lengthOfService" placeholder="请选择工龄" clearable @change="lengthValueChange(AddDateModel.lengthOfService)">
                        <el-option v-for="item in lengthOfServiceoptions" :key="item.dictCode" :label="item.dictLabel" :value="item.dictLabel">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="学历：" prop="education">
                    <el-select v-model="AddDateModel.education" placeholder="请选择学历" clearable @change="educationValueChange(AddDateModel.education)">
                        <el-option v-for="item in educationRange" :key="item.dictCode" :label="item.dictLabel" :value="item.dictLabel">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="职称：" prop="title">
                    <el-select v-model="AddDateModel.title" placeholder="请选择职称" clearable @change="titleValueChange(AddDateModel.title)">
                        <el-option v-for="item in titleRange" :key="item.dictCode" :label="item.dictLabel" :value="item.dictLabel">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="职务：" prop="post">
                    <el-select v-model="AddDateModel.post" placeholder="请选择职务" @change="postChange(AddDateModel.post)">
                        <el-option v-for="item in postoptions" :key="item.dictCode" :label="item.dictLabel" :value="item.dictLabel">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="政治面貌：" prop="polout">
                    <el-select v-model="AddDateModel.polout" placeholder="请选择政治面貌" @change="polChange(AddDateModel.polout)">
                        <el-option v-for="item in poloptions" :key="item.dictCode" :label="item.dictLabel" :value="item.dictLabel">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="编制：" prop="autstr">
                    <el-select v-model="AddDateModel.autstr" placeholder="请选择编制" @change="autChange(AddDateModel.autstr)">
                        <el-option v-for="item in autoptions" :key="item.dictCode" :label="item.dictLabel" :value="item.dictLabel">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="职工类型：" prop="emptype">
                    <el-select v-model="AddDateModel.emptype" placeholder="请选择职工类型" @change="empChange(AddDateModel.emptype)">
                        <el-option v-for="item in empoptions" :key="item.dictCode" :label="item.dictLabel" :value="item.dictLabel">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属部门：" prop="departme">
                    <el-cascader :value="depValue" expand-trigger="hover" :options="depOptions" placeholder="请选择所属部门" ref='countrySelectRef' clearable @change='depChange'></el-cascader>
                </el-form-item>
                <el-form-item label="岗位：" prop="ganpost">
                    <el-cascader v-model="postValue" expand-trigger="hover" :options="postOptions" placeholder="请选择岗位" popper-class="disableFirstLevel" :props="props" ref='postSelectRef' clearable @change='postValueChange'></el-cascader>
                </el-form-item>
                <el-form-item label="学科：" prop="bralear">
                    <el-select v-model="AddDateModel.bralear" multiple placeholder="请选择学科" clearable @change="braValueChange(AddDateModel.bralear)">
                        <el-option v-for="item in baroptions" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注：" prop="text">
                    <el-input type="textarea"  maxlength="250" show-word-limit placeholder="请填写备注" @input="change($event)" v-model="AddDateModel.text"></el-input>
                </el-form-item>

                <div class="btnCen">
                    <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing" @click="addKeshi">{{diaBtn}}</el-button>
                     <el-button v-else type="info" :loading="addDepClickKing"></el-button>
                </div>

            </el-form>
        </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
export default {
    data() {
        var jobnumSpace = async (rule, value, callback) => {
            if (!value) {
                callback(new Error("请填写工号"));
            } else if (value) {
                // console.log(this.diaTit);
                if (this.diaTit == "修改人员信息") {
                    if (this.reayJob == value) {
                        callback();
                    } else {
                        let data = _qs.stringify({
                            workId: value,
                        });
                        let res = await this.$axios.judgeHosDepartmentUser(
                            data
                        );
                        // console.log(res);
                        if (res.data.code == 200) {
                            callback();
                        } else {
                            callback(new Error(res.data.msg));
                        }
                    }
                } else {
                    let data = _qs.stringify({
                        workId: value,
                    });
                    let res = await this.$axios.judgeHosDepartmentUser(data);
                    // console.log(res);
                    if (res.data.code == 200) {
                        callback();
                    } else {
                        callback(new Error(res.data.msg));
                    }
                }
            } else {
                callback();
            }
        };

        return {
            downurl: this.GLOBAL + "/openApi/hosDepartmentUser/importExcel",
            fileList: [], // 上传文件的列表
            limitUpload: 1, //最大允许上传个数
            cesuanExpClick: true,
            cesuanExpClickTwo: false,
            importExpClick: true,
            importExpClickTwo: false,
            reayJob: "",
            diaBtn: "新增",
            diaTit: "新增人员信息",
            titleRange: [],
            educationRange: [],
            inpatRange: [],
            postRange: [],
            workyearRange: [],
            baroptions: [],
            value: "",
            // 添加科室的添加按钮
            addDepClick: true,
            addDepClickKing: false,
            // 添加科室
            AddDateModel: {
                departme: "", //所属部门：
                emptype: "", //职工类型：
                post: "", //职务
                title: "", //职称
                jobnum: "", //工号
                name: "", //姓名
                idcard: "", //身份证
                sex: "", //性别
                age: "", //年龄
                education: "", //学历
                polout: "", //政治面貌：
                autstr: "", //编制：
                ganpost: [], //岗位：
                bralear: [], //学科
                lengthOfService: "",
            },
            lengthOfServiceoptions: [],
            depValue: "",
            postValue: [],
            props: { multiple: true },
            AddDateRules: {
                lengthOfService: [
                    {
                        required: true,
                        message: "请选择工龄",
                        trigger: "change",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "请输入名称",
                        trigger: "blur",
                    },
                ],
                departme: [
                    {
                        required: true,
                        message: "请选择所属部门",
                        trigger: "change",
                    },
                ],
                emptype: [
                    {
                        required: true,
                        message: "请选择职工类型",
                        trigger: "change",
                    },
                ],
                post: [
                    {
                        required: true,
                        message: "请选择职务",
                        trigger: "change",
                    },
                ],
                title: [
                    {
                        required: true,
                        message: "请选择职称",
                        trigger: "change",
                    },
                ],
                jobnum: [
                    { required: true, validator: jobnumSpace, trigger: "blur" },
                    // {
                    //     required: true,
                    //     message: "请输入工号",
                    //     trigger: "blur",
                    // },
                ],
                idcard: [
                    {
                        required: true,
                        message: "请输入身份证",
                        trigger: "blur",
                    },
                ],
                age: [
                    {
                        required: true,
                        message: "请选择出生日期",
                        trigger: "change",
                    },
                ],
                sex: [
                    {
                        required: true,
                        message: "请选择性别",
                        trigger: "change",
                    },
                ],
                education: [
                    {
                        required: true,
                        message: "请选择学历",
                        trigger: "change",
                    },
                ],
                polout: [
                    {
                        required: true,
                        message: "请选择政治面貌",
                        trigger: "change",
                    },
                ],
                autstr: [
                    {
                        required: true,
                        message: "请选择编制",
                        trigger: "change",
                    },
                ],
                ganpost: [
                    {
                        required: true,
                        message: "请选择岗位",
                        trigger: "change",
                    },
                ],
                bralear: [
                    {
                        required: true,
                        message: "请选择学科",
                        trigger: "change",
                    },
                ],
            },
            empoptions: [],
            autoptions: [],
            poloptions: [],
            postoptions: [],
            sexoptions: [
                {
                    value: 1,
                    label: "男",
                },
                {
                    value: 2,
                    label: "女",
                },
            ],
            // 点击添加科室弹出框
            AdddialogVisible: false,
            // 科室管理的分页
            // 当前页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            // 总数
            total: 1,
            // 表格数据
            tableData: [],
            // 科室id
            departmentId: window.sessionStorage.getItem("departmentId"),
            wardId: "", //病区id
            depOptions: [],
            // 查询用的业务名称
            searchForm: {
                nameLike: "",
                workId: "",
                departName: "",
                ganpost: [],
                bralear: "",
            },
            searganArr: [],
            departOpt: [],
            aaaa: [],
            postOptions: [],
            postType: "", //岗位职责类型（0医生 1护士）
            shareScopeEnd: [],
            seareScopeEnd: [],
        };
    },
    computed: {
        token() {
            return {
                Authorization:
                    "Bearer " + window.sessionStorage.getItem("token"),
            };
        },
    },
    created() {
        this.listhosDepartmentUser();
        this.findDepartment();
        this.hosDisciplines();
        this.hosPostRe();
        this.findpost();
    },
    methods: {
        async postCan(val) {
            let data = {
                dictType: val,
                status: "0",
            };
            let res = await this.$axios.findBySys(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.status == 200) {
                if (val == "hos_department_user_position") {
                    this.postoptions = res.data;
                } else if (val == "hos_authorized_strength") {
                    this.autoptions = res.data;
                } else if (
                    val == "hos_department_user_educational_background"
                ) {
                    this.educationRange = res.data;
                } else if (val == "hos_department_user_professional_title") {
                    this.titleRange = res.data;
                } else if (val == "hos_employee_type") {
                    this.empoptions = res.data;
                } else if (val == "hos_political_outlook") {
                    this.poloptions = res.data;
                } else if (val == "hos_department_user_length_of_service") {
                    this.lengthOfServiceoptions = res.data;
                }
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        // 搜索框
        searchList() {
            this.pageNum = 1;
            this.listhosDepartmentUser();
        },
        searchworkId() {
            this.pageNum = 1;
            this.listhosDepartmentUser();
        },
        searchDep(val) {
            this.pageNum = 1;
            this.searchForm.departName = val;
            this.listhosDepartmentUser();
        },
        // 搜索岗位
        searchpost(val) {
            this.pageNum = 1;
            // console.log(val);
            this.searganArr = [];
            let changeFlag = false;
            let nowItem = [];
            let firstItem = [];
            if (this.seareScopeEnd.length == 0) {
                this.searchForm.ganpost = val;
            } else {
                // 与原数组比对
                this.searchForm.ganpost.forEach((item) => {
                    if (item[0] !== this.seareScopeEnd[0][0]) {
                        // 一级标签不同
                        changeFlag = true;
                        firstItem.push(item);
                    }
                });
            }
            if (changeFlag && firstItem.length) {
                this.searchForm.ganpost = firstItem;
            } else if (!changeFlag && nowItem.length) {
                this.searchForm.ganpost = nowItem;
            }
            this.seareScopeEnd = this.searchForm.ganpost;
            // console.log(this.seareScopeEnd)
            for (let i = 0; i < this.seareScopeEnd.length; i++) {
                // console.log(this.seareScopeEnd[i]);
                this.searganArr.push(this.seareScopeEnd[i][1]);
            }
            // console.log(this.searganArr);
            this.listhosDepartmentUser();
        },
        searchbra(val) {
            this.pageNum = 1;
            this.searchForm.bralear = val;
            this.listhosDepartmentUser();
        },
        change(e) {
            this.$forceUpdate(e);
        },
        async findpost() {
            let data = {
                dataType: "0", //科室绩效专用数据类型（ 0岗位职责 ）
            };
            let { data: res } = await this.$axios.optionselect(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // console.log(res.data);
                for (let i = 0; i < res.data.length; i++) {
                    this.postOptions.push({
                        label: res.data[i].dictName,
                        value: res.data[i].dictType,
                        dictType: res.data[i].dictType,
                        children: [],
                    });
                }
                this.postfindBySys();
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        async postfindBySys() {
            for (let i = 0; i < this.postOptions.length; i++) {
                let data = {
                    type: this.postOptions[i].dictType,
                    deleteFlag: "0",
                    dictName: this.postOptions[i].label,
                };
                let res = await this.$axios.findBySys(data);
                // console.log(res);
                if (res.status == "200") {
                    for (let n = 0; n < res.data.length; n++) {
                        this.postOptions[i].children.push({
                            label: res.data[n].dictLabel,
                            value: res.data[n].dictLabel,
                        });
                    }
                }
            }
        },
        async findDepartment() {
            let data = {
                hospitalId: window.sessionStorage.getItem("hospitalId"), //登录后返回的 医院id
                nameLike: this.searchForm.nameLike, //查询用的 科室名称
                orderByColumn: "updateDate", //排序参数 updateDate 更新时间
                isAsc: "desc", //asc 升序 desc降序
            };
            let { data: res } = await this.$axios.findDepartment(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.departOpt = res.data;
                for (let i = 0; i < res.data.length; i++) {
                    this.depOptions.push({
                        label: res.data[i].name,
                        value: res.data[i].id,
                        children: [],
                    });
                }
                this.findByhosInward();
            }
        },
        async findByhosInward() {
            for (let i = 0; i < this.depOptions.length; i++) {
                let finddata = {
                    deleteFlag: "0",
                    id: "",
                    departmentId: this.depOptions[i].value,
                };
                let findres = await this.$axios.findByhosInward(finddata);
                // console.log(findres);
                if (findres.code == 401) {
                    this.$router.push("/login");
                } else if (findres.status == 200) {
                    // console.log(findres.data);
                    for (let n = 0; n < findres.data.length; n++) {
                        if (
                            findres.data[n].departmentId ==
                            this.depOptions[i].value
                        ) {
                            this.depOptions[i].children.push({
                                label: findres.data[n].name,
                                value: findres.data[n].id,
                            });
                        }
                    }
                }
            }
        },
        // 岗位
        async hosPostRe() {
            let data = {
                deleteFlag: "0",
            };
            let res = await this.$axios.hosPostRe(data);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.status == 200) {
                // console.log(res.data);
                this.postRange = res.data;
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        postChange(val) {
            this.AddDateModel.post = val;
        },
        polChange(val) {
            this.AddDateModel.polout = val;
        },
        autChange(val) {
            this.AddDateModel.autstr = val;
        },
        empChange(val) {
            this.AddDateModel.emptype = val;
        },
        depChange(value) {
            // console.log(value);
            this.AddDateModel.departme = "";
            this.depinpatientWardId = "";
            if (value.length > 0) {
                this.AddDateModel.departme =
                    this.$refs["countrySelectRef"].getCheckedNodes()[0].path[0];
                this.depinpatientWardId =
                    this.$refs["countrySelectRef"].getCheckedNodes()[0].path[1];
            }
            // console.log(this.AddDateModel.departme, this.depinpatientWardId);
        },
        postValueChange(value) {
            // console.log(value);
            this.AddDateModel.ganpost = [];
            let changeFlag = false;
            let nowItem = [];
            let firstItem = [];
            if (this.shareScopeEnd.length == 0) {
                this.postValue = value;
            } else {
                // 与原数组比对
                this.postValue.forEach((item) => {
                    if (item[0] !== this.shareScopeEnd[0][0]) {
                        // 一级标签不同
                        changeFlag = true;
                        firstItem.push(item);
                    }
                });
            }
            if (changeFlag && firstItem.length) {
                this.postValue = firstItem;
            } else if (!changeFlag && nowItem.length) {
                this.postValue = nowItem;
            }
            this.shareScopeEnd = this.postValue;
            // console.log(this.shareScopeEnd);
            for (let i = 0; i < this.shareScopeEnd.length; i++) {
                this.postType = this.shareScopeEnd[i][0];
                this.AddDateModel.ganpost.push(this.shareScopeEnd[i][1]);
            }
            // console.log(this.AddDateModel.ganpost);
            // console.log(this.postType);
        },
        // 学科
        async hosDisciplines() {
            let data = {
                deleteFlag: "0",
            };
            let res = await this.$axios.hosDisciplines(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.status == 200) {
                this.baroptions = res.data;
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        // 工龄
        async workyearfindBySys() {
            let data = {
                dictType: "hos_department_user_length_of_service",
                status: "0",
            };
            let res = await this.$axios.findBySys(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.status == 200) {
                this.workyearRange = res.data;
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        // 选择工龄
        workyearValueChange(val) {
            // console.log(val);
            this.AddDateModel.workyear = val;
        },
        braValueChange(val) {
            // console.log(val);
            this.AddDateModel.bralear = val;
        },
        // 选择职称
        titleValueChange(val) {
            // console.log(val);
            this.AddDateModel.title = val;
        },
        // 选择学历
        educationValueChange(val) {
            // console.log(val);
            this.AddDateModel.education = val;
        },
        // 选择工龄
        lengthValueChange(val) {
            this.AddDateModel.lengthOfService = val;
        },
        // 选择所属病区
        inpatValueChange(val) {
            // console.log(val);
            this.AddDateModel.inpatientWardId = val;
        },
        // 选择人员类型
        typeValueChange(val) {
            // console.log(val);
            this.AddDateModel.type = val;
        },
        // 科室管理分页
        handleCurrentChange(val) {
            this.pageNum = val;
            // console.log(`当前页: ${val}`);
            this.listhosDepartmentUser();
        },
        uploading() {
            this.importExpClick = false;
            this.importExpClickTwo = true;
        },
        // 导入
        successFile(res) {
            this.importExpClick = true;
            this.importExpClickTwo = false;
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.$message.success("导入成功");
                this.listhosDepartmentUser();
                this.$refs.uploadone.clearFiles();
            } else {
                this.$refs.uploadone.clearFiles();
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        // 人员信息导出
        async exportData() {
            this.cesuanExpClick = false;
            this.cesuanExpClickTwo = true;
            let postResponsibilityId, disciplineId;
            if (this.searganArr.length > 0) {
                if (this.searganArr.length > 1) {
                    postResponsibilityId = this.searganArr.join("|");
                } else {
                    postResponsibilityId = this.searganArr[0] + "";
                }
            }
            if (this.searchForm.bralear.length > 0) {
                if (this.searchForm.bralear.length > 1) {
                    disciplineId = this.searchForm.bralear.join("|");
                } else {
                    disciplineId = this.searchForm.bralear[0] + "";
                }
            }

            let params = {
                deleteFlag: "0", //数据状态（0正常 1已删除）
                departmentId: this.searchForm.departName, //所属科室id
                name: this.searchForm.nameLike, //姓名
                postResponsibility: postResponsibilityId, //岗位职责id（多个id用 竖线隔开）
                disciplineId: disciplineId, //学科id（多个id用 竖线隔开）
                workId: this.searchForm.workId, //工号
            };
            let res = await this.$axios.exportExcel(params);
            // console.log(res);
            if (res.status == 200) {
                if (res.data.code == 401) {
                    this.$router.push("/login");
                } else if (res.code == 500) {
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                } else if (res.status == 200) {
                    let updateTime = this.addDate();
                    let blob = new Blob([res.data], {
                        type: "application/x-xls",
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    //配置下载的文件名
                    link.download = "人员信息-" + updateTime + ".xls";
                    link.click();
                    this.cesuanExpClick = true;
                    this.cesuanExpClickTwo = false;
                }
            }
        },
        //获取当前年月日
        addDate() {
            const nowDate = new Date();
            const date = {
                year: nowDate.getFullYear(),
                month: nowDate.getMonth() + 1,
                date: nowDate.getDate(),
            };
            const newmonth = date.month > 10 ? date.month : "0" + date.month;
            const day = date.date > 10 ? date.date : "0" + date.date;
            let updateTime = date.year + "-" + newmonth + "-" + day;
            return updateTime;
        },
        // 点击添加科室
        depaddKeshi() {
            this.AdddialogVisible = true;
            this.callIn();
            // 学历
            this.postCan("hos_department_user_educational_background");
            // 职务
            this.postCan("hos_department_user_position");
            // 编制
            this.postCan("hos_authorized_strength");
            // 职称
            this.postCan("hos_department_user_professional_title");
            // 职工类型
            this.postCan("hos_employee_type");
            // 政治面貌
            this.postCan("hos_political_outlook");
            // 工龄
            this.postCan("hos_department_user_length_of_service");
        },
        // 点击添加科室的时候取消正则
        AdddialogVisibleClose() {
            this.shareScopeEnd = [];
            this.seareScopeEnd = [];
            this.AdddialogVisible = false;
            this.$refs.AddDateRef.resetFields();
            this.diaTit = "新增人员信息";
            this.diaBtn = "新增";
            this.reayJob = "";
            this.AddDateModel = {
                departme: "", //所属部门：
                emptype: "", //职工类型：
                post: "", //职务
                title: "", //职称
                jobnum: "", //工号
                name: "", //姓名
                idcard: "", //身份证
                sex: "", //性别
                age: "", //年龄
                education: "", //学历
                polout: "", //政治面貌：
                autstr: "", //编制：
                ganpost: [], //岗位：
                bralear: [], //学科
                lengthOfService: "",
            };
            this.depValue = "";
            this.postValue = [];
        },
        // 点击操作添加科室的添加
        addKeshi() {
            if (this.diaBtn == "新增") {
                // 新增
                this.addhosDepartmentUser();
            } else if (this.diaBtn == "修改") {
                // 修改
                this.editdialogKeshi();
            }
        },
        addhosDepartmentUser() {
            this.$refs.AddDateRef.validate(async (valid) => {
                if (valid) {
                    this.addDepClick = false;
                    this.addDepClickKing = true;
                    let data = _qs.stringify({
                        departmentId: this.AddDateModel.departme, //所属科室id
                        inpatientWardId: this.depinpatientWardId, //所属病区id
                        postType: this.postType, //岗位职责类型（0医生 1护士）
                        postResponsibility:
                            this.AddDateModel.ganpost.toString(), //岗位职责id（多个id用逗号隔开）
                        disciplineId: this.AddDateModel.bralear.toString(), //学科id（多个id用逗号隔开）
                        workId: this.AddDateModel.jobnum, //工号
                        name: this.AddDateModel.name, //姓名
                        idNumber: this.AddDateModel.idcard, //身份证
                        dateOfBirth: this.AddDateModel.age, //年龄
                        lengthOfService: this.AddDateModel.lengthOfService, //工龄
                        sex: this.AddDateModel.sex, //性别  1男  2女
                        educationalBackground: this.AddDateModel.education, //学历
                        professionalTitle: this.AddDateModel.title, //职称
                        post: this.AddDateModel.post, //职务
                        politicalOutlook: this.AddDateModel.polout, //政治面貌
                        authorizedStrength: this.AddDateModel.autstr, //编制
                        employeeType: this.AddDateModel.emptype, //职工类型
                        remark: this.AddDateModel.text, //备注备注
                    });
                    let res = await this.$axios.addhosDepartmentUser(data);
                    // console.log(res);
                    this.addDepClick = true;
                    this.addDepClickKing = false;
                    if (res.data.code == 401) {
                        this.$router.push("/login");
                    } else if (res.status == 200) {
                        this.listhosDepartmentUser();
                        this.AdddialogVisibleClose();
                        this.AdddialogVisible = false;
                    } else {
                        this.$message({
                            message: res.data.msg,
                            type: "error",
                        });
                    }
                }
            });
        },
        getPidMenuList(pid) {
            let cid_list = [];
            this.depOptions.forEach((item, index) => {
                if (item.value == pid) {
                    cid_list = [item.value];
                    return false;
                } else {
                    if (item.children) {
                        let newCid_list = [item.value];
                        let list = nodefun(item.children, pid, newCid_list);
                        if (list) {
                            cid_list = list;
                        }
                    }
                }
            });
            // 递归函数
            function nodefun(newVal, newId, newCid_list) {
                let flag = false;
                newVal.forEach((j) => {
                    if (j.value == newId) {
                        newCid_list.push(j.value);
                        flag = true;
                    } else {
                        if (j.children) {
                            let cid_list = JSON.parse(
                                JSON.stringify(newCid_list)
                            );
                            cid_list.push(j.value);
                            let list = nodefun(j.children, newId, cid_list);
                            if (list) {
                                newCid_list = list;
                                flag = true;
                            }
                        }
                    }
                });
                if (flag) {
                    return newCid_list;
                }
            }
            return cid_list;
        },
        // 点击操作删除
        async deleteKeshi(row) {
            const confirmResult = await this.$confirm("确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch((err) => err);

            if (confirmResult !== "confirm") {
                return this.$message.info("已经取消删除");
            }
            let data = _qs.stringify({
                id: row.id,
            });
            let res = await this.$axios.deleteDepartment(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.data.code == 200) {
                this.listhosDepartmentUser();
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        callIn() {
            this.workyearfindBySys();
        },
        // 点击操作修改科室的添加
        editKeshi(row) {
            this.callIn();
            // 学历
            this.postCan("hos_department_user_educational_background");
            // 职务
            this.postCan("hos_department_user_position");
            // 编制
            this.postCan("hos_authorized_strength");
            // 职称
            this.postCan("hos_department_user_professional_title");
            // 职工类型
            this.postCan("hos_employee_type");
            // 政治面貌
            this.postCan("hos_political_outlook");
            // 工龄
            this.postCan("hos_department_user_length_of_service");
            // console.log(row);
            this.wardId = row.id;
            this.diaBtn = "修改";
            this.diaTit = "修改人员信息";
            this.AdddialogVisible = true;
            this.hosfindByKey();
        },
        async hosfindByKey() {
            let data = {
                id: this.wardId,
            };
            let res = await this.$axios.hosfindByKey(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.status == 200) {
                // console.log(res.data);
                this.reayJob = res.data.workId;
                this.AddDateModel.jobnum = res.data.workId;
                this.AddDateModel.name = res.data.name;
                this.AddDateModel.idcard = res.data.idNumber;
                this.AddDateModel.age = res.data.dateOfBirth;
                this.AddDateModel.sex = res.data.sex;
                this.AddDateModel.education = res.data.educationalBackground;
                this.AddDateModel.polout = res.data.politicalOutlook;
                this.AddDateModel.autstr = res.data.authorizedStrength;
                this.AddDateModel.emptype = res.data.employeeType;
                this.AddDateModel.post = res.data.post;

                this.AddDateModel.text = res.data.remark;
                this.AddDateModel.title = res.data.professionalTitle;
                this.AddDateModel.education = res.data.educationalBackground;
                this.AddDateModel.lengthOfService = res.data.lengthOfService;
                this.AddDateModel.bralear = [];
                this.AddDateModel.ganpost = [];
                this.postValue = [];

                let array = [];
                // 回显岗位

                if (res.data.postResponsibility) {
                    let postRes = res.data.postResponsibility.split(",");
                    for (let i = 0; i < postRes.length; i++) {
                        array.push([res.data.postType, postRes[i]]);
                    }
                }
                // console.log(array);
                this.AddDateModel.ganpost = res.data.postResponsibility;
                this.postValue = array;
                this.shareScopeEnd = array;

                this.postType = res.data.postType;

                if (res.data.disciplineId) {
                    let disRes = res.data.disciplineId.split(",");
                    for (let i = 0; i < disRes.length; i++) {
                        this.AddDateModel.bralear.push(Number(disRes[i]));
                    }
                }

                this.depValue = this.getPidMenuList(
                    Number(res.data.inpatientWardId)
                );
                this.AddDateModel.departme = this.depValue;
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        // 点击修改科室用户管理的添加
        editdialogKeshi() {
            // console.log(this.AddDateModel.ganpost);
            if (this.AddDateModel.departme instanceof Array) {
                let aa = this.AddDateModel.departme;
                this.AddDateModel.departme = aa[0];
                this.depinpatientWardId = aa[1];
            }
            this.$refs.AddDateRef.validate(async (valid) => {
                if (!valid) return;
                this.addDepClick = false;
                this.addDepClickKing = true;
                let data = _qs.stringify({
                    id: this.wardId, //科室人员id
                    hospitalId: window.sessionStorage.getItem("hospitalId"), //医院id
                    departmentId: this.AddDateModel.departme, //所属科室id
                    inpatientWardId: this.depinpatientWardId, //所属病区id
                    postType: this.postType, //岗位职责类型（0医生 1护士）
                    postResponsibility: this.AddDateModel.ganpost.toString(), //岗位职责id（多个id用逗号隔开）
                    disciplineId: this.AddDateModel.bralear.toString(), //学科id（多个id用逗号隔开）
                    workId: this.AddDateModel.jobnum, //工号
                    name: this.AddDateModel.name, //姓名
                    idNumber: this.AddDateModel.idcard, //身份证
                    dateOfBirth: this.AddDateModel.age, //年龄
                    lengthOfService: this.AddDateModel.lengthOfService, //工龄
                    sex: this.AddDateModel.sex, //性别  1男  2女
                    educationalBackground: this.AddDateModel.education, //学历
                    professionalTitle: this.AddDateModel.title, //职称
                    post: this.AddDateModel.post, //职务
                    politicalOutlook: this.AddDateModel.polout, //政治面貌
                    authorizedStrength: this.AddDateModel.autstr, //编制
                    employeeType: this.AddDateModel.emptype, //职工类型
                    remark: this.AddDateModel.text, //备注备注
                });
                let res = await this.$axios.updaDepartment(data);
                // console.log(res);
                this.addDepClick = true;
                this.addDepClickKing = false;
                if (res.data.code == 401) {
                    this.$router.push("/login");
                } else if (res.status == 200) {
                    this.listhosDepartmentUser();
                    this.AdddialogVisible = false;
                    this.AdddialogVisibleClose();
                    this.$message({
                        message: res.data.msg,
                        type: "success",
                    });
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: "error",
                    });
                }
            });
        },
        // 分页显示病区列表数据
        async listhosDepartmentUser() {
            let postResponsibilityId, disciplineId;
            if (this.searganArr.length > 0) {
                if (this.searganArr.length > 1) {
                    postResponsibilityId = this.searganArr.join("|");
                } else {
                    postResponsibilityId = this.searganArr[0] + "";
                }
            }
            if (this.searchForm.bralear.length > 0) {
                if (this.searchForm.bralear.length > 1) {
                    disciplineId = this.searchForm.bralear.join("|");
                } else {
                    disciplineId = this.searchForm.bralear[0] + "";
                }
            }

            let data = {
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示条数
                deleteFlag: "0", //数据状态（0正常 1已删除）
                departmentId: this.searchForm.departName, //所属科室id
                name: this.searchForm.nameLike, //姓名
                postResponsibility: postResponsibilityId, //岗位职责id（多个id用 竖线隔开）
                disciplineId: disciplineId, //学科id（多个id用 竖线隔开）
                workId: this.searchForm.workId, //工号
            };
            let { data: res } = await this.$axios.listhosDepartmentUser(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.disableFirstLevel {
    .el-cascader-panel {
        .el-scrollbar:first-child {
            .el-checkbox {
                display: none;
            }
        }
    }
}

.RightDiv {
    margin: 0 auto;
}

.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
}
.depart-addnum {
    width: 100%;
}

.AddUser {
    float: right;
    margin-bottom: 10px;
}

.AddUserDiv {
    width: 95%;
    margin: 0 auto;
}

.depar-dia {
    width: 100%;
}
.collapse-btn {
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    background: rgb(134 140 146);
}
/deep/ .el-radio-button__inner {
    width: 100px;
}
.el-pagination {
    margin-top: 20px;
}

/deep/ .el-dialog__body {
    text-align: inherit;
}

.el-main {
    .table-title {
        display: flex;
        margin-bottom: 10px;
        flex-wrap: wrap;
        div {
            margin-left: 6px;
        }
        /deep/ .el-input .el-input__inner {
            width: 160px;
        }
        .index-add {
            margin-left: 20px;
            margin-bottom: 10px;
        }
    }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

.hosrow {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.activeStatus {
    background: rgb(102, 204, 0);
}

.noStatus {
    background: #ccc;
}

.noStatus,
.activeStatus {
    width: 25px;
    height: 25px;
    color: white;
    line-height: 25px;
    text-align: center;
    margin: 0 auto;
}

.btnCen {
    text-align: center;
    button {
        width: 150px;
    }
}

/deep/ .el-input--suffix .el-input__inner,
/deep/ .el-input .el-input__inner {
    width: 200px;
}

/deep/ .el-textarea__inner {
    width: 520px !important;
}

.searForm {
    float: right;
}

/deep/ .ageClas .el-input__suffix {
    right: 15px;
}
/deep/ .ageClas .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 200px;
}

/deep/ .el-upload-list__item-name,
/deep/ .el-upload-list__item-status-label {
    display: none !important;
}
</style>

